var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", [
            _c(
              "div",
              [
                _vm.api.isLoading
                  ? _c("v-skeleton-loader", {
                      ref: "skeleton",
                      attrs: { type: "table" },
                    })
                  : _vm._e(),
                _vm.data != null
                  ? _c("v-data-table", {
                      staticClass: "elevation-1",
                      attrs: {
                        search: _vm.searchCompanies,
                        items: _vm.data,
                        headers: _vm.dataHeader,
                        dense: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item.Index",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " + _vm._s(_vm.data.indexOf(item) + 1) + " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "item.whatssap_name",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                item.user_id == _vm.$store.getters.getUserId
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "PageWhatssapTemplateUpdate",
                                            params: { id: item.whatssap_id },
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.whatssap_name) + " "
                                        ),
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        " " + _vm._s(item.whatssap_name) + " "
                                      ),
                                    ]),
                              ]
                            },
                          },
                          {
                            key: "top",
                            fn: function () {
                              return [
                                _c(
                                  "v-toolbar",
                                  {
                                    staticClass: "py-3",
                                    attrs: { height: "auto", flat: "" },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "mt-3 mx-3" },
                                          [
                                            _c(
                                              "v-row",
                                              { staticClass: "mb-3" },
                                              [
                                                _c(
                                                  "v-toolbar-title",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold text-h4 my-3",
                                                  },
                                                  [_vm._v(" Whatssap ")]
                                                ),
                                                _c("v-spacer"),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      color: "primary",
                                                      to: {
                                                        name: "PageWhatssapTemplateCreate",
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" New ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1722735142
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }